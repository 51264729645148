import { template as template_6ec305af384340cca3200860c6efb14b } from "@ember/template-compiler";
const WelcomeHeader = template_6ec305af384340cca3200860c6efb14b(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
