import { template as template_b62507351ddd49e6b6d35a90080eb5dc } from "@ember/template-compiler";
const FKLabel = template_b62507351ddd49e6b6d35a90080eb5dc(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
